<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Meus cadernos</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Meus cadernos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1
                              v-if="!$store.state.fastPersonalizacao.meus_cadernos_nome"
                              class="aluno_font_color"
                            >
                              Meus
                              <span>cadernos</span>
                            </h1>
                            <h1
                              v-else
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.meus_cadernos_nome }}
                            </h1>
                            <p class="aluno_font_color">
                              Veja as atividades salvas
                            </p>
                            <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <section
                            class="fd-app-welcome text-left d-none fast-menu-lives-aluno-iuea mt-4 mb-4 ml-4"
                          >
                            <h1
                              v-if="!$store.state.fastPersonalizacao.meus_cadernos_nome"
                              class="aluno_font_color"
                            >
                              Meus cadernos
                            </h1>
                            <h1>{{ $store.state.fastPersonalizacao.meus_cadernos_nome }}</h1>
                          </section>
                          <!-- /fd-app-welcome -->
                          <div class="col-12 mt-2">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-4 mb-1">
                                <h4
                                  v-if="!$store.state.fastPersonalizacao.meus_cadernos_nome"
                                  class="aluno_font_color"
                                >
                                  Lista de cadernos ({{ fastCadernosFiltro.length }})
                                </h4>
                                <h4
                                  v-else
                                  class="aluno_font_color"
                                >
                                  {{ $store.state.fastPersonalizacao.meus_cadernos_nome }} encontrados ({{ fastCadernosFiltro.length }})
                                </h4>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6 mt-4 mb-1">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-6 pr-0">
                                    <input
                                      v-model="fastCadernoNovo.nome_caderno"
                                      placeholder="Nome"
                                      type="text"
                                      class="form-control"
                                    >
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <button
                                      class="btn btn-primary btn-block"
                                      @click="cadastraNovoCaderno()"
                                    >
                                      <small>Criar</small>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div v-if="fastCadernosFiltro.length">
                                  <tabs
                                    v-for="caderno in fastCadernosFiltro"
                                    :key="caderno.id_caderno"
                                    :options="{ useUrlFragment: false }"
                                  >
                                    <tab
                                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                                      name="Detalhes"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-6">
                                              <h6>Nome</h6>
                                              <input
                                                v-model="caderno.nome_caderno"
                                                type="text"
                                                class="form-control"
                                                @keyup.prevent="caderno.alteracao_pendente = true"
                                              >
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-6">
                                              <button class="btn btn-danger btn-sm mt-4">
                                                Excluir
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          v-if="caderno.alteracao_pendente"
                                          class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                                        >
                                          <button
                                            class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                            @click.prevent="atualizaCaderno(caderno)"
                                          >
                                            <small>
                                              <i
                                                class="fa fa-floppy-o"
                                                aria-hidden="true"
                                              /> &nbsp;Salvar alterações
                                            </small>
                                          </button>
                                          <button
                                            class="btn btn-secondary btn-sm mt-2 ml-2"
                                            @click.prevent="getCadernosUsuario()"
                                          >
                                            <small>
                                              <i
                                                class="fa fa-ban"
                                                aria-hidden="true"
                                              /> &nbsp;Cancelar
                                            </small>
                                          </button>
                                        </div>
                                      </div>
                                    </tab>
                                    <tab
                                      prefix="<i class='fa fa-file-pdf' aria-hidden='true'></i>&nbsp"
                                      name="Atividades"
                                      :suffix="'<span class=&quot;suffix&quot;>' + (caderno.atividades ? caderno.atividades.length : '0') + '</span>'"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                                          <h4>Atividades</h4>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <table
                                            v-if="caderno.atividades"
                                            class="table table-striped table-bordered"
                                          >
                                            <thead>
                                              <tr class="bg-dark text-white">
                                                <th scope="col">
                                                  Título da atividade
                                                </th>
                                                <th
                                                  class="text-center"
                                                  scope="col"
                                                >
                                                  Tipo
                                                </th>
                                                <th
                                                  class="text-center"
                                                  scope="col"
                                                >
                                                  Conteúdo
                                                </th>
                                                <th
                                                  class="text-center"
                                                  scope="col"
                                                >
                                                  Opções
                                                </th>
                                              </tr>
                                            </thead>

                                            <tbody v-if="caderno.atividades.length">
                                              <tr
                                                v-for="atividade in caderno.atividades"
                                                :key="atividade.id_atividade"
                                              >
                                                <td>{{ atividade.titulo_atividade }}</td>
                                                <td
                                                  class="text-center"
                                                >
                                                  {{ formataTipoAtividade(atividade.tipo_atividade) }}
                                                </td>
                                                <td class="text-center">
                                                  <div v-if="atividade.tipo_atividade == 'RF'">
                                                    <a
                                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                                      :href="corrigeLinkConcurseiro(atividade.referencia_atividade)"
                                                      target="_blank"
                                                      download
                                                    >
                                                      <small>
                                                        <i
                                                          class="fa fa-eye"
                                                          aria-hidden="true"
                                                        /> &nbsp;Visualizar
                                                      </small>
                                                    </a>
                                                  </div>
                                                  <div
                                                    v-if="atividade.tipo_atividade == 'VA' || atividade.tipo_atividade == 'AD'"
                                                  >
                                                    <button
                                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                                      @click="visualizarAtividade(atividade)"
                                                    >
                                                      <small>
                                                        <i
                                                          class="fa fa-play"
                                                          aria-hidden="true"
                                                        /> &nbsp;Visualizar
                                                      </small>
                                                    </button>
                                                  </div>
                                                  <div v-else>
                                                    <a
                                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + atividade.id_curso + '/aula/' + atividade.id_aula"
                                                    >
                                                      <small>Ir para aula</small>
                                                    </a>
                                                  </div>
                                                </td>
                                                <td class="text-center">
                                                  <button class="btn btn-danger btn-sm mt-2 ml-2">
                                                    <small>
                                                      <i
                                                        class="fa fa-ban"
                                                        aria-hidden="true"
                                                      /> &nbsp; Excluir
                                                    </small>
                                                  </button>
                                                </td>
                                              </tr>
                                            </tbody>
                                            <tbody v-else>
                                              <tr>
                                                <td colspan="4">
                                                  Nenhuma atividade cadastrada
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </tab>
                                  </tabs>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastCadernosFiltro"
                                @changePage="pageOfCadernos = $event"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modals -->
    <modal
      name="modalVisualizarAtividade"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Atividade - {{ atividadeVisualizar.titulo_atividade }}</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalVisualizarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div
              v-if="atividadeVisualizar.tipo_atividade == 'VA'"
              class="col-12"
            >
              <iframe
                :src="atividadeVisualizar.referencia_atividade"
                width="100%"
                height="315"
                frameborder="0"
              />
            </div>
            <div
              v-else
              class="col-12"
            >
              <audio
                controls
                class="d-block m-auto"
              >
                <source
                  :src="atividadeVisualizar.referencia_atividade"
                  type="audio/mpeg"
                >Seu navegador não suporte o player de audio.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAlunoMeusCadernos",
  components: {
    Pagination,
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      // Cadernos
      fastCadernosTotal: [],
      fastCadernosFiltro: [],
      pageOfCadernos: [],
      fastCadernoNovo: {
        id_caderno: 0,
        nome_caderno: "",
        id_usuario: 0,
        datacriacao: "",
        data_alteracao: "",
      },
      // Visualização de atividade
      atividadeVisualizar: [],
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.getCadernosUsuario();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Cadernos
    async getCadernosUsuario() {
      this.fastCadernosTotal = [];
      this.fastCadernosFiltro = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_caderno_usuario/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((c) => (c.atividades = []));

          obj.forEach((c) => {
            this.getAtividadesCadernosUsuario(c, c.id_caderno);
          });
        }
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async cadastraNovoCaderno() {
      if (this.fastCadernoNovo.nome_caderno) {
        let acao = "insere";
        if (this.fastCadernoNovo.id_caderno) acao = "atualiza";

        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_caderno_usuario/" + acao,
            this.fastAjaxOptions("POST", JSON.stringify(this.fastCadernoNovo))
          );

          let json = await resp.json();
          let obj = Array.from(json);

          this.fastCadernoNovo = {
            id_caderno: 0,
            nome_caderno: "",
            id_usuario: 0,
            datacriacao: "",
            data_alteracao: "",
          };

          this.getCadernosUsuario();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Caderno salvo com sucesso",
          });
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async getAtividadesCadernosUsuario(caderno, id_caderno) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_caderno_usuario_atividade/lista?id_caderno=" +
            id_caderno,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          caderno.atividades = obj;
        }

        this.fastCadernosTotal.push(caderno);
        this.fastCadernosFiltro.push(caderno);
        console.log(this.fastCadernosFiltro);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    formataTipoAtividade(e) {
      switch (e) {
        case "RF":
          return "Arquivo";
          break;
        case "AD":
          return "Audio";
          break;
        case "VA":
          return "Video Aula";
          break;
        case "PS":
          return "Pesquisa";
          break;
        case "AV":
          return "Avaliação";
          break;
        case "SM":
          return "Simulado";
          break;
        case "QZ":
          return "Quiz";
          break;
        case "LV":
          return "Live";
          break;
        case "UP":
          return "Upload";
          break;
        default:
          return "Desconhecido";
      }
    },
    visualizarAtividade(atividade) {
      this.atividadeVisualizar = atividade;
      this.showModal("modalVisualizarAtividade");
    },
    async atualizaCaderno(caderno) {},
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
